import { Stack } from "@mui/system";
import { Roles } from "../config/enum"
import { useStore } from "../stores/store";
import { Typography } from "@mui/material";
import { Outlet } from "react-router-dom";

interface PageAuthorizerProps {
  roles: Roles[];
}

export const PageAuthorizer = ({ roles }: PageAuthorizerProps): JSX.Element => {
  const { account } = useStore().accountStore

  const authorized = account?.roles.some(item => roles.includes(item));

  if (!authorized) return (
    <Stack>
      <Typography>You are not allowed to access this page.</Typography>
    </Stack>
  )
  
  return <Outlet />;
}