import { Route, Routes, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useStore } from './app/stores/store';

import { ThemeProvider } from '@mui/material/styles';
import theme from './app/config/theme';

import DashboardLayout from './app/component/layout/DashboardLayout';
import Home from './features/home/Home';
import ExamplePage from './features/example/ExamplePage';
import ExampleForm from './features/example/ExampleForm';
import UserPage from './features/user/UserPage';
import Tester from './features/tester/Tester';
import ServerError from './features/errors/ServerError';
import NotFound from './features/errors/NotFound';
import LoginPage from './features/account/LoginPage';
import RkdModal from './app/component/helper/RkdModal';
import RkdSnackbar from './app/component/helper/RkdSnackbar';
import RkdDialog from './app/component/helper/RkdDialog';
import RkdBackdrop from './app/component/helper/RkdBackdrop';
import RouteAuth from './app/component/helper/RouteAuth';
import AccessDenied from './features/errors/AccessDenied';
import { Roles } from './app/config/enum';
import TemplatePage from './features/template/TemplatePage';
import TemplateFormPage from './features/template/TemplateFormPage';
import { PagePage } from './features/page/PagePage';
import PageFormPage from './features/page/PageFormPage';
import { CategoryPage } from './features/category/CategoryPage';
import { PostPage } from './features/post/PostPage';
import { PostFormPage } from './features/post/PostFormPage';
import DashboardLayout2 from './app/component/layout/DashboardLayout2';

import { LicenseInfo } from '@mui/x-license-pro';
import { ComponentPage } from './features/component/ComponentPage';
import { TestPage } from './features/test/TestPage';
import { ContentTypePage } from './features/contentType/ContentTypePage';
import SitemapPage from './features/sitemap/SitemapPage';
import { MediaPage } from './features/media/MediaPage';
import { PageApprovalPage } from './features/pageApproval/PageApprovalPage';
import { ContentTypeApprovalPage } from './features/contentTypeApproval/ContentTypeApprovalPage';
import { PagePublishPage } from './features/pagePublish/PagePublishPage';
import PagePublishDetailPage from './features/pagePublish/PagePublishDetailPage';
import ContentTypeFormPage from './features/contentType/ContentTypeFormPage';
import { ContentTypePublishPage } from './features/contentTypePublish/ContentTypePublishPage';
import ContentTypePublishDetailPage from './features/contentTypePublish/ContentTypePublishDetailPage';
import ContentTypeCategoryPage from './features/contentTypeCategory/ContentTypeCategoryPage';

import './assets/scss/app.scss';
// import "./assets/scss/announcement-grid-section.scss"
// import "./assets/scss/article-grid-section.scss"
// import "./assets/scss/article.scss"
// import "./assets/scss/books-grid-section.scss"
// import "./assets/scss/card-slider-large.scss"
// import "./assets/scss/card-slider-right.scss"
// import "./assets/scss/card-slider.scss"
// import "./assets/scss/card1.scss"
// import "./assets/scss/card1x2-small.scss"
// import "./assets/scss/card2.scss"
// import "./assets/scss/card2x2.scss"
// import "./assets/scss/carousel-header.scss"
// import "./assets/scss/contact-card.scss"
// import "./assets/scss/frame-video.scss"
// import "./assets/scss/full-image-description.scss"
// import "./assets/scss/full-image.scss"
// import "./assets/scss/header.scss"
// import "./assets/scss/image-description.scss"
// import "./assets/scss/latest-article-page.scss"
// import "./assets/scss/latest-article.scss"
// import "./assets/scss/logo-collection.scss"
// import "./assets/scss/logo-slider.scss"
// import "./assets/scss/long-text.scss"
// import "./assets/scss/operational-spot.scss"
// import "./assets/scss/stacked-card.scss"
// import "./assets/scss/team-slider.scss"
// import "./assets/scss/timeline.scss"

import { TimelinePage } from './features/timeline/TimelinePage';
import { ForgotPasswordPage } from './features/forgotPassword/ForgotPasswordPage';
import { ChangePasswordPage } from './features/changePassword/ChangePasswordPage';
import { MediaCropper } from './features/media/MediaCropper';
import { ConfirmSession } from './features/account/ConfirmSession';
import SettingsPage from './features/setting/SettingsPage';
import { SessionPage } from './features/session/SessionPage';
import { PageAuthorizer } from './app/component/PageAuthorizer';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY || '');

const App = () => {
  const location = useLocation();
  const { commonStore, accountStore } = useStore();

  useEffect(() => {
    if (commonStore.token) {
      accountStore.getUser().finally(() => commonStore.setAppLoaded());
    } else {
      commonStore.setAppLoaded();
    }
  }, [accountStore, commonStore]);

  if (!commonStore.appLoaded) return <RkdBackdrop />

  return (
    <ThemeProvider theme={theme}>
      <RkdModal />
      <RkdDialog />
      <RkdSnackbar />
      {/* <MediaCropper /> */}
      <Routes>
        {/* <Route path="/test" element={<TestPage />} /> */}
        <Route path="/auth/login" element={<LoginPage />} />
        <Route path="/auth/forgot-password" element={<ForgotPasswordPage />} />
        <Route element={<DashboardLayout2 drawerVariant='temporary' />} >
          <Route element={<PageAuthorizer roles={[Roles.Admin, Roles.Creator]} />}>
            <Route path='/templates/form' element={<TemplateFormPage />} />
            <Route path='/templates/form/:id' element={<TemplateFormPage />} />
            <Route path='/pages/form' element={<PageFormPage />} />
            <Route path='/pages/form/:id' element={<PageFormPage />} />
          </Route>
          <Route element={<PageAuthorizer roles={[Roles.Admin, Roles.Creator, Roles.Approver, Roles.Publisher, Roles.Superadmin]} />}>
            <Route path='/pages/view/:id' element={<PageFormPage />} />
          </Route>
        </Route>
        <Route element={<DashboardLayout drawerVariant='permanent' />} >
          <Route index={true} element={<Home></Home>} />
          <Route path='/pages/list' element={<PagePage />} />
          <Route path='/content-types/list/:id' element={<ContentTypePage />} />
          <Route path='/content-types/category/list' element={<ContentTypeCategoryPage />} />

          <Route element={<PageAuthorizer roles={[Roles.Admin, Roles.Creator, Roles.Approver, Roles.Publisher, Roles.Superadmin]} />}>
            <Route path='/content-types/:id/view/:contentTypeId' element={<ContentTypeFormPage />} />
          </Route>

          <Route element={<PageAuthorizer roles={[Roles.Superadmin, Roles.Admin, Roles.Approver]} />}>
            <Route path='/pages/approval' element={<PageApprovalPage />} />
            <Route path='/content-types/approval' element={<ContentTypeApprovalPage />} />
          </Route>

          <Route element={<PageAuthorizer roles={[Roles.Superadmin, Roles.Admin, Roles.Publisher]} />}>  
            <Route path='/pages/publish' element={<PagePublishPage />} />
            <Route path='/pages/publish/:pageId' element={<PagePublishDetailPage />} />
            <Route path='/content-types/publish' element={<ContentTypePublishPage />} />
            <Route path='/content-types/publish/:contentTypeId' element={<ContentTypePublishDetailPage />} />
          </Route>

          <Route element={<PageAuthorizer roles={[Roles.Superadmin, Roles.Admin, Roles.Creator]} />}>  
            <Route path='/templates' element={<TemplatePage />} />
            <Route path='/components' element={<ComponentPage />} />
            <Route path='/components/timeline' element={<TimelinePage />} />
            <Route path='/content-types/:id/form' element={<ContentTypeFormPage />} />
            <Route path='/content-types/:id/form/:contentTypeId' element={<ContentTypeFormPage />} />
            <Route path='/sitemap' element={<SitemapPage />} />
            <Route path='/medias' element={<MediaPage />} />
          </Route>
          
          {/* <Route path='/category' element={<CategoryPage />} />
          <Route path='/post' element={<PostPage />} />
          <Route path='/post/form' element={<PostFormPage />} /> */}
          {/* <Route path='/example' element={<ExamplePage />} />
          <Route path='/example/edit/:id' element={<ExampleForm key={location.key} />} />
          <Route path='/example/create' element={<ExampleForm key={location.key} />} /> */}
          <Route element={<PageAuthorizer roles={[Roles.Superadmin]} />}>
            <Route path='/users/*' element={<UserPage />} />
            <Route path='/tester' element={<Tester />} />
            <Route element={<SettingsPage key={location.key} />}>
              <Route path='/settings/change-password' element={<ChangePasswordPage />} />
              <Route path='/settings/session' element={<SessionPage />} />
            </Route>
          </Route>
          {/* Error */} 
          <Route path='/access-denied' element={<AccessDenied />} />
          <Route path='/server-error' element={<ServerError />} />
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
};

export default observer(App);
