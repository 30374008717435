import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader"
import ContentTypePublishDataGrid from "./ContentTypePublishDataGrid"
import { HtmlHeader } from "../../app/component/HtmlHeader"

export const ContentTypePublishPage = () => {
  return (
    <>
      <HtmlHeader title={`Content Type Publish | Nusantara Regas`} />
      <DashboardLayoutHeader
        breadcrumbs={[
          { label: 'pages', href: '/pages/publish' },
          { label: 'publish', href: '/pages/publish' },
        ]}
        pageTitle="Publish content type"
      />
      <ContentTypePublishDataGrid />
    </>
  )
}