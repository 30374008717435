import { observer } from "mobx-react-lite"
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader"
import { useStore } from "../../app/stores/store"
import ContentTypeCategoryGrid from "./ContentTypeCategoryGrid"
import { CreateCategoryModal } from "./CreateCategoryModal"
import { Stack } from "@mui/material"
import { HtmlHeader } from "../../app/component/HtmlHeader"

const ContentTypeCategoryPage = () => {
  const { modalStore } = useStore()
  
  return (
    <>
      <HtmlHeader title={`Category | Nusantara Regas`} />
      <DashboardLayoutHeader
        breadcrumbs={[
          { label: 'content types', href: '/content-types/category/list' },
          { label: 'category', href: '/content-types/category/list' },
          { label: 'list', href: '/content-types/category/list' }
        ]}
        pageTitle="Category"
      >
        <Stack spacing={2}>
          <DashboardLayoutHeader.Button variant="contained" onClick={() => modalStore.open(<CreateCategoryModal />)}>
            Add category
          </DashboardLayoutHeader.Button>
        </Stack>
      </DashboardLayoutHeader>
      <ContentTypeCategoryGrid />
    </>
  )
}

export default observer(ContentTypeCategoryPage)