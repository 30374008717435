import { Alert, Box, Grid, Link as MLink, Stack } from '@mui/material';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from '../../app/config/theme';
import RkdForm from '../../app/component/form/RkdForm';
import RkdTextInput from '../../app/component/form/RkdTextInput';
import { IAccountLoginValues } from '../../app/models/account';
import { useStore } from '../../app/stores/store';
import RkdCheckbox from '../../app/component/form/RkdCheckbox';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { HtmlHeader } from '../../app/component/HtmlHeader';

const initialValuesLogin: IAccountLoginValues = {
  email: '',
  password: '',
  rememberMe: false,
}

const validationSchema = Yup.object({
  email: Yup.string(),
  password: Yup.string().required(),
});


const LoginPage = () => {  
  const { accountStore } = useStore();
  const [error, setError] = useState('')

  return (
    <>
    <ThemeProvider theme={theme}>
      <HtmlHeader title='Login Admin | Nusantara Regas' />
      <CssBaseline />
      <Grid container sx={{ height: '100vh' }}>        
        <Grid item xs={5}>
          <Box sx={{ height:'100%', display: 'flex', alignItems: 'center'  }}>
            <Box sx={{ m:'auto', width: '50%', display: 'flex', flexDirection: 'column', justifyContent:'center' }}>
              <Box display="flex" justifyContent="space-between" marginBottom={2}>
                <img src='/assets/images/logo-color.png' alt='Logo Nusantara Regas' style={{ width: '300px', objectFit:'cover', margin: 'auto' }} />
              </Box>
              <RkdForm initialValues={initialValuesLogin} validationSchema={validationSchema} buttonSubmitText="Submit"
                  submitAction={(val) => accountStore.login(val).catch((error) => {
                    setError(String(error?.response?.data ?? 'Something went wrong'))})}
              >
                <RkdTextInput name="email" type="text" label="Email" />
                <RkdTextInput name="password" type="password" label="Password" />
                {!!error ? <Alert sx={{ mt: -1, mb: 1 }} severity='error'>{error}</Alert> : <></>}
                <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between" alignItems="center">
                  <RkdCheckbox name='rememberMe' label='Remember Me?' />
                  <MLink component={Link} to="/auth/forgot-password">Forgot password?</MLink>
                </Stack>
              </RkdForm>
            </Box>
          </Box>
        </Grid>   
        <Grid item xs={7}>
          <Box sx={{ height:'100%', }}>
            <img src='/assets/images/bg-login.jpg' alt='Hero' style={{ width:'100%', height: '100%', objectFit:'cover' }} />
          </Box>
        </Grid>
      </Grid>      
    </ThemeProvider>
    </>
  );
};

export default LoginPage;