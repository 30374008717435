import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader"
import PagePublishDataGrid from "./PagePublishDataGrid"
import { HtmlHeader } from "../../app/component/HtmlHeader"

export const PagePublishPage = () => {
  return (
    <>
      <HtmlHeader title={`Page Publish | Nusantara Regas`} />
      <DashboardLayoutHeader
        breadcrumbs={[
          { label: 'pages', href: '/pages/publish' },
          { label: 'publish', href: '/pages/publish' },
        ]}
        pageTitle="Publish page"
      />
      <PagePublishDataGrid />
    </>
  )
}