import { Box } from "@mui/material"
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader"
import TimelineGrid from "./TimelineGrid"
import TimelineModal from "./TimelineModal"
import { useState } from "react"
import { HtmlHeader } from "../../app/component/HtmlHeader"

export const TimelinePage = () => {
  const [isOpen, setIsOpen] = useState(false)
  
  return (
    <Box>
      <HtmlHeader title={`Timeline | Nusantara Regas`} />
      <DashboardLayoutHeader 
        breadcrumbs={[
          {
            label: 'Timeline',
            href: '/timeline',
          }
        ]}
        pageTitle="Timeline"
      >
        <DashboardLayoutHeader.Button onClick={() => setIsOpen(true)} variant="contained">
          Add Timeline
        </DashboardLayoutHeader.Button>
      </DashboardLayoutHeader>
      <TimelineGrid />
      <TimelineModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </Box>
  )
}