import { Box } from "@mui/material";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import { useParams } from "react-router-dom";
import ContentTypeGrid from "./components/ContentTypeGrid";
import { history } from "../..";
import { useQuery } from "../../utils/useQuery";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { HtmlHeader } from "../../app/component/HtmlHeader";

export const ContentTypePage = observer(() => {
  const { id } = useParams();
  const query = useQuery()
  const name = query.get('name')
  const { isCreator, isSuperAdmin } = useStore().accountStore

  const canAdd = isCreator || isSuperAdmin
  
  return (
    <Box>
      <HtmlHeader title={`${name} | Nusantara Regas`} />
      <DashboardLayoutHeader
        pageTitle={name ?? ""}
        breadcrumbs={[
          {
            label: "content types",
            href: `/content-types/list/${id}?name=${name}`,
          },
          {
            label: name?.toLowerCase() ?? "",
            href: `/content-types/list/${id}?name=${name}`,
          },
          {
            label: "list",
            href: `/content-types/list/${id}?name=${name}`,
          },
        ]}
      >
        <DashboardLayoutHeader.Button disabled={!canAdd} onClick={() => history.push(`/content-types/${id}/form?name=${name}`)} variant="contained">
          Add {name}
        </DashboardLayoutHeader.Button>
      </DashboardLayoutHeader>
      <ContentTypeGrid contentTypeName={name as any} />
    </Box>
  );
});
