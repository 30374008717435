import { StyledDataGridPremium } from "../../app/component/StyledDataGridPremium";
import { useStore } from "../../app/stores/store";
import { Dispatch, SetStateAction, useEffect } from "react";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { CheckRounded, DoNotDisturbOffRounded, EditRounded, Visibility } from "@mui/icons-material";
import { history } from "../..";
import { ApprovalResponseType } from "../../app/config/enum";
import { ContentTypeApproveRejectTypeValues } from "../../app/models/contentTypeApproval";
import { convertUTCtoGMTplus7 } from "../../utils/convertUTCtoGMTplus7";

interface ContentTypeApprovalDataGridProps {
  setApproveRejectState: Dispatch<SetStateAction<ContentTypeApproveRejectTypeValues | null>>
}

const ContentTypeApprovalDataGrid = ({ setApproveRejectState }: ContentTypeApprovalDataGridProps) => {
  const { getGrid, grid, gridLoading, params, setParams } =
    useStore().contentTypeApprovalStore;
  const { isCreator, isSuperAdmin, isApprover } = useStore().accountStore

  useEffect(() => {
    getGrid();
  }, [getGrid]);

  const allowApprove = isCreator || isSuperAdmin || isApprover

  return (
    <StyledDataGridPremium
      columns={[
        {
          field: 'action',
          headerName: '',
          width: 150,
          renderCell: (params) => {
            return (
              <Stack direction="row">
                <Tooltip title={isApprover ? 'View' : 'Edit'}>
                  <span>
                    <IconButton color={isApprover ? 'inherit' : 'info'} onClick={() => history.push(`/content-types/${params.row.contentTypeId}/${isApprover ? 'view' : 'form'}/${params.row.id}?name=${params.row.contentTypeName}&disabled=true`)}>
                      {isApprover ? <Visibility /> : <EditRounded />}
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Approve">
                  <span>
                    <IconButton color="success" disabled={!allowApprove} onClick={() => setApproveRejectState(({
                      contentTypeValueId: params.row.id,
                      status: ApprovalResponseType.APPROVED,
                      notes: ''
                    }))}>
                      <CheckRounded />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Reject">
                  <span>
                    <IconButton color="error" disabled={!allowApprove} onClick={() => setApproveRejectState(({
                      contentTypeValueId: params.row.id,
                      status: ApprovalResponseType.REJECTED,
                      notes: ''
                    }))}>
                      <DoNotDisturbOffRounded />
                    </IconButton>
                  </span>
                </Tooltip>
              </Stack>
            )
          }
        },
        {
          field: "name",
          headerName: "Name",
          minWidth: 250,
          flex: 1
        },
        {
          field: "createdByName",
          headerName: "Created By",
          width: 200,
        },
        {
          field: "createdOn",
          headerName: "Created On",
          width: 200,
          renderCell: (params) =>
            params.row?.createdOn
              ? format(convertUTCtoGMTplus7(params.row.createdOn), "dd MMM yyyy - HH:mm")
              : "-",
        },
      ]}
      loading={gridLoading}
      rows={grid.data}
      rowCount={grid.rowCount}
      sx={{ minHeight: 200 }}
      autoHeight
      pagination
      paginationMode="server"
      page={params.currentPage}
      onPageChange={(v) => setParams('currentPage', v)}
      onPageSizeChange={v => setParams('pageSize', v)}
    />
  );
};

export default observer(ContentTypeApprovalDataGrid);
