import { Helmet } from "react-helmet";

export function HtmlHeader({ title }: { title: string }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta 
        httpEquiv="Content-Security-Policy" 
        content={`
          default-src 'self' https://dev.nrwebcms.dev.rekadia.co.id; 
          script-src 'self' 'unsafe-inline'; 
          style-src 'self' 'unsafe-inline'; 
          img-src 'self' data: *;
          connect-src 'self' https://dev.nrwebcms.dev.rekadia.co.id;
        `}
      />
      <meta httpEquiv="X-XSS-Protection" content="1; mode=block" />
      <meta httpEquiv="X-Frame-Options" content="DENY" />
      <meta httpEquiv="X-Content-Type-Options" content="nosniff" />
      <meta name="referrer" content="strict-origin-when-cross-origin" />
    </Helmet>
  )
}